import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route,Switch } from "react-router-dom";
import './index.css';
import Home from './Home';
import MyItems from './MyItems';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/myitems" element={<MyItems />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);


