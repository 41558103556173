import { useState, useEffect } from "react";
import { getAddress } from 'sats-connect';
//import { AppConfig, UserSession, showConnect, getAddress } from '@stacks/connect';
import { StacksTestnet, StacksMainnet  } from '@stacks/network';

let address = localStorage.getItem('address');
let address2 = localStorage.getItem('address2');

async function connectWallet(){
    const myRedirect = localStorage.getItem('redirect');

    // const userAddressesReq = await window.btc.request('getAddresses');
    // let userAddresses = userAddressesReq["result"]["addresses"].find(o => o.type === 'p2tr')


    const getAddressOptions = {
        payload: {
          purposes: ['ordinals', 'payment'],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type:'Mainnet'
          },
        },
        onFinish: (response) => {
            console.log(response);
            localStorage.setItem('address',response.addresses[0].address);
            localStorage.setItem('pubkey',response.addresses[0].publicKey);
            localStorage.setItem('address2',response.addresses[1].address);
        },
        onCancel: () => {}
        }
          
        try{

            await getAddress(getAddressOptions);
          
           // throw new Error ("some error") // We can make our own errors and handle them in the catch block
          }
          
          catch(error){
            alert("\nPlease use Xverse wallet. \nDownload it at: xverse.app/download\n\nMore wallet compatibility coming soon.")
          //  const userAddressesReq = await window.btc?.request('getAddress');
        //let userAddresses = userAddressesReq["result"]["addresses"].find(o => o.type === 'p2tr')
        console.log("test");
          }
          
          finally{
            //Code to be executed after the code in try and/or catch blocks is executed
          }

      


    
    // if(myRedirect && myRedirect !=""){
    //   localStorage.removeItem('redirect');
    //   window.top.location.href = "https://merkletrees.xyz/" + myRedirect;
    // }else{
    //     window.location.reload();
    // }
    window.location.reload();
  }

  const handleClick = function(button) {
    if (button === 'disconnect') {
      localStorage.removeItem('address');
      localStorage.removeItem('pubkey');
      window.location.reload(false);
    }
    if (button === 'myitems') {
        window.top.location.href = "https://merkletrees.xyz";
    }
  };

  
  const handleBtnClick = () => handleClick('disconnect');
  const handleAddClick = () => handleClick('myitems');

  function formatBitcoinAddress(address) {
    const firstThree = address.slice(0, 5);
    const lastThree = address.slice(-5);
    return `${firstThree}...${lastThree}`;
  }
    function ShowButton(props){

        if (!address){
            return (<div className="box"><div><button  onClick={async () => await connectWallet()} className="connectbtn">Connect Wallet</button></div></div>);
         }else{
            return (<div className="box"><div><div className="topaddy"><a href="/myitems" >{formatBitcoinAddress(address)}</a></div>
            <div><button onClick={async () => await handleBtnClick()} className="connectbtn">Disconnect</button></div>
            </div></div>);
         }
    }
        
export default ShowButton;