import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';

const handleClick = function(button) {
    if (button === 'clear') {
       localStorage.removeItem('txs');
       window.location.reload(false);
    }
  };

  
  const handleBtnClick = () => handleClick('clear');


  async function CheckConfs(){

    const feerslt = await axios.get('https://mempool.space/api/v1/fees/recommended');
    let fee = feerslt.data.fastestFee;
  
  }

 function getTXStatus(tx){

    return(
        <div className='txcount'><div className='txemoji'></div></div>
    )
  }


function formatBitcoinAddress(address) {
    const firstThree = address.toString().slice(0, 15);
    const lastThree = address.toString().slice(-5);
    return `${firstThree}...`;
  }

function Transactions({ txs, orders }) {

    const listItems = txs.map((item, index) => (
      <li className='txlistitem' key={index}>{getTXStatus(item)}<div><a target='_blank' href={'https://mempool.space/tx/'+item}>{formatBitcoinAddress(item)}</a></div></li>
    ));
  
    return (
        <div className="transactionbox">Recent Mint TX's
      <ul className='txul'>{listItems}</ul>
      <button className="clearbtn" onClick={async () => await handleBtnClick()} title="Clear recent transactions">X{/*<img src='details.png' height="25"/>*/}</button>
      </div>
    );
  }

  export default Transactions;