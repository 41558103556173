import "./App.css";
import { useState, useEffect } from "react";
import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import { StacksTestnet, StacksMainnet  } from '@stacks/network';
import * as btc from '@scure/btc-signer';
import axios from 'axios';
import parse from 'html-react-parser';
import  ShowButton from "./components/ShowButton.js";
import  Logo from "./components/Logo.js";

function Buffer (arg, encodingOrOffset, length) {
  // Common case.
  if (typeof arg === 'number') {
    if (typeof encodingOrOffset === 'string') {
      throw new TypeError(
        'The "string" argument must be of type string. Received type number'
      )
    }
    return allocUnsafe(arg)
  }
  return from(arg, encodingOrOffset, length)
}
let treeCount = 0;

const MyItems = () => {
  const appConfig = new AppConfig();
  const userSession = new UserSession({ appConfig });
  const [ chain, setChain ] = useState(undefined);
  const [ session, setSession ] = useState(undefined);
  const [ client, setClient ] = useState(undefined);
  const [ address, setAddress ] = useState(undefined);
  const [ pubKey, setPubKey ] = useState(undefined);
  const [ ItemTrees, setItemTrees ] = useState(undefined);
  const [ MyTrees, setMyTrees ] = useState("");
  let treecheck = false;

  if(!localStorage.getItem('pubkey')){
    window.top.location.href = "https://merkletrees.xyz";
    localStorage.setItem('redirect',"myitems");
  }

  const myPubKey = localStorage.getItem('pubkey');
  const myAddress = localStorage.getItem('address');

  let myTreeHTML = "";
  
  ListItems();



  async function ListItems(){

    const ordbotreslt = await axios.get('https://api.ordinalsbot.com/search?text=MERKc91e44d0f11d982b6c5ea122b44208780bd24d388cb028fcd39b991TREES',
    {
      data:{
        'text':'MERKc91e44d0f11d982b6c5ea122b44208780bd24d388cb028fcd39b991TREES'
      },
    headers: {
      'x-api-key':'3ab5f41b-8b51-4463-a564-6ffbff01f80a'
  }}
  )
    const hirodata = await axios.get('https://api.hiro.so/ordinals/v1/inscriptions?address='+myAddress)

    if(ordbotreslt.data.results.length > 0 && hirodata.data.results.length > 0){


      if(hirodata.data.results.length > 0){
        for (const result of hirodata.data.results) {
 

          let match = false;
          for (const result2 of ordbotreslt.data.results) {
           
            if(result2.inscriptionid == result.id){
              treeCount = treeCount + 1;
              myTreeHTML = myTreeHTML + "<div class='blocks'><iframe scrolling='no' class='frame' src='https://ordinals.com/preview/" + result.id + "'></iframe></div>"
            }
          }
           
          }
        }
    
    }
    
    setMyTrees(myTreeHTML);
    
  }

        



  function formatBitcoinAddress(address) {
    const firstThree = address.slice(0, 5);
    const lastThree = address.slice(-5);
    return `${firstThree}...${lastThree}`;
  }

  

  return (
    <div className="main">
      <div><ShowButton /></div>
      <Logo />
      <div className="mintbox">
        <div className="mintText">
        <h3>My Merkle Trees</h3>
        </div>

      
        { myPubKey && MyTrees &&(
          <div className="mytreeholder">
          {parse(MyTrees)}
          </div>
        )
        }
            
        {treeCount === 0 &&(
        <div className="notrees">No Merkle Tree's yet!  If you've minted one,you may have to wait<br/>for the bitcoin transaction to complete or the ordinal to be indexed.</div>
        )}
        {!myPubKey && (
          <div className="notrees">Connect your wallet</div>
        )}
      </div>
             

          </div>
  );
}

export default MyItems;
