import "./App.css";
import { useState, useEffect } from "react";
import { AppConfig, UserSession, showConnect, getAddress } from '@stacks/connect';
import { StacksTestnet, StacksMainnet  } from '@stacks/network';
import * as btc from '@scure/btc-signer';
import  ShowButton from "./components/ShowButton.js";
import  Transactions from "./components/Transactions.js";
import axios from 'axios';
import  Logo from "./components/Logo.js";
import { bytesToHex, hexToBytes } from '@noble/hashes/utils';
import {Buffer} from "buffer";
window.Buffer = window.Buffer || require("buffer").Buffer;
import { Inscription } from "ordinalsbot";
import { sendBtcTransaction } from 'sats-connect'


// @ts-ignore
window.Buffer = Buffer;

let inscription = new Inscription("", "live");
let fee = 100;

const Home = () => {
  const appConfig = new AppConfig();
  const userSession = new UserSession({ appConfig });
  const [ chain, setChain ] = useState(undefined);
  const [ session, setSession ] = useState(undefined);
  const [ client, setClient ] = useState(undefined);
  const [ address, setAddress ] = useState(undefined);
  const [ pubKey, setPubKey ] = useState(undefined);

  const myPubKey = localStorage.getItem('pubkey');
  const myAddress = localStorage.getItem('address');
  const myAddress2 = localStorage.getItem('address2');
  
  var thisaddress = myAddress;
  var str="";

  if(localStorage.getItem('orders')){
    var orders = [localStorage.getItem('orders').toString().split(',')];
  }else{
    var orders=[];
  }

  if(localStorage.getItem('txs')){
    var txs = [localStorage.getItem('txs').toString().split(',')];
  }else{
    var txs=[];
  }
  

  var thtml = "<strong>test</strong>";
  function getBytes(string){
    return Buffer.byteLength(string, 'utf8')
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

async function getfees(){

  const feerslt = await axios.get('https://mempool.space/api/v1/fees/recommended');
  let fee = feerslt.data.fastestFee;

}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


  function updateAddress(address){
    thisaddress = address.target.value
   // console.log(thisaddress);
  }

  let paletteChoice = getRandomInt(0,5); //0-5
  let trunkChoice = getRandomInt(0,4); //0-4
  let angle1 = getRandomArbitrary(1.8,2.2)//1.9-2.1
  let grassChoice = getRandomInt(0,3); //0-3
  let randSeed = getRandomInt(0,9007199254740991);

  //const postdata = Buffer.from('{"p":"brc-20","op":"deploy","tick":"MERK","max":"21000000","lim":"100000"}').toString('base64'); //
  const postdata = Buffer.from('<html lang="en"><head><meta charset="utf-8"><meta charset="utf-8"><meta name="description" content="Collection:Merkle Tree\'s"><meta name="author" content="@DogeOfDogeSt"><meta name="validate" content="MERKc91e44d0f11d982b6c5ea122b44208780bd24d388cb028fcd39b991TREES"><script type="module">let r="";const ORDINALS_LIBS={fflate:"6bac7ab4ce8d5d32f202c2e31bba2b5476a18275802b4e0595c708760f9f56b5i0",p5:"255ce0c5a0d8aca39510da72e604ef8837519028827ba7b7f723b7489f3ec3a4i0",tree:"b5ac3668c137a8c7de0a8668082084271c5486eb8d3378d0154c38ca46f767e1i0"};function addScriptToPage(a){let e=document.createElement("script");e.innerHTML=a,document.body.appendChild(e)}async function getOrdinalsScript(a){let e=await fetch("/content/"+a),t=await e.text();return t.replace("2e3,23")}function gunzip64(a){return fflate.strFromU8(fflate.gunzipSync(new Uint8Array(Array.from(atob(a)).map(a=>a.charCodeAt(0)))))}addScriptToPage(await getOrdinalsScript(ORDINALS_LIBS.fflate)),addScriptToPage(await gunzip64(await getOrdinalsScript(ORDINALS_LIBS.p5))),addScriptToPage(await getOrdinalsScript(ORDINALS_LIBS.tree));let myp5=new p5(eval(r));</script></head><body style="margin: 0px"><script>let minttime = '+Math.floor(new Date().getTime() / 1000)+';let mintseed = '+randSeed+';let paletteChoice = '+paletteChoice+';let trunkChoice = '+trunkChoice+';let angle1 = '+angle1+';let grassChoice = '+grassChoice+';</script></body></html>').toString('base64');
  
  async function mint(){

    try {
      // create new order
      const order = await inscription.createOrder({
        files: [
          {
            size: getBytes(postdata),
            type: "html/text",
            name: "mytree.html",
            dataURL: "data:htmltext;base64,"+postdata,
          },
        ],
        lowPostage: true,
        receiveAddress: thisaddress,
        fee: fee,
      });
      
      console.log(order);
    //  console.log(order.charge.address + "|" + order.charge.amount + "|" + order.charge.status);

     await getfees();

      const sendBtcOptions = {
        payload: {
          network: {
            type: "Mainnet",
          },
          recipients: [
            {
              address: order.charge.address,
              amountSats: BigInt(order.charge.amount),
            },
            {
              address: 'bc1q58qwad4nzsqmccy58d76wggvz6wn8jef59wzt4',
              amountSats: BigInt(35000),
            },
          ],
          senderAddress: myAddress2,
          fee: fee
        },
        onFinish: (response) => {
         console.log(response);
         orders.push(order.id+"|"+response);
         txs.push(response);
         localStorage.setItem(response,order.id);
         localStorage.setItem('txs',response);
         window.location.reload(false);
        },
        onCancel: () => {},
      };
      
      await sendBtcTransaction(sendBtcOptions);

      //   const resp = await window.btc?.request('sendTransfer', {
      //   address: order.charge.address,
      //   amount: order.charge.amount
      // });

    } catch (error) {
      console.log("Exception: ", error);
    }

  }

 // console.log(orders);


  return (
    <div className="main">
      <div><ShowButton /></div>
      <Logo />
      <div className="mintbox">
      <img src="tree.png" /><br />
      </div>
      {
            myPubKey && (
              <div>
              <div className="mintbox">
              <div className="mintText">
              <h3>Merkle Trees: Where Bitcoin Ordinals Blossom into Living Art!</h3>
              Step into a world where Merkle Trees are more than just data structures—they are masterpieces in the making. Each Merkle Tree is a unique work of art, born through the magic of procedural generation. These living sculptures don't rush their beauty; starting out as a small stump, some may take a year or even longer to fully flourish and reveal their intricate colors & structure.<br/>
              </div>
              <div>Receiver: <input className="rcvinput" type="text" onChange={(e) =>updateAddress(e)} defaultValue={myAddress} ></input>
              <div><button onClick={async () => await mint()} className="mintbtn">Mint Now</button></div>
              
              <div dangerouslySetInnerHTML={{ __html: str }} />
              
              </div>
              </div>
              </div>
            )
              }
              {
              myPubKey && txs.length >= 1 &&(
              <Transactions async='true' orders={orders} txs={txs.toString().split(',')}/>
              )
              }

          {
            !myPubKey && (
              <div>
            <div className="mintbox">
            <div className="mintText">
            <h3>Merkle Trees: Where Bitcoin Ordinals Blossom into Living Art!</h3>
              Step into a world where Merkle Trees are more than just data structures—they are masterpieces in the making. Each Merkle Tree is a unique work of art, born through the magic of procedural generation. These living sculptures don't rush their beauty; starting out as a small stump, some may take a year or even longer to fully flourish and reveal their intricate colors & structure.<br/><br/>
            </div>
                <div>Connect your Wallet to mint.</div>
              </div>
            </div>
            
            )
              }

    </div>
  );
}

export default Home;
